import React, { useState } from "react";
import { useNavigate } from 'react-router-dom';
import { Alert, Button, Form, Card, Row, Col } from "react-bootstrap";
// custom components
import { SimplePageTitle } from "../../../components/titles/pageTitles";
import ImageForm from "../../../components/forms/ImageForm";
import AudiosForm from './UploadAudiosForm';
import ModalStepsLoader from "../../../components/modals/loaderStepsModal";
import ParentCategorySelect from './ParentCategorySelect';
// utils
import { wait } from '../../../utils/wait';
import { getNameWithoutExt } from '../../../utils/extensions';
// custom hooks
import useModalWithSteps from "../../../hooks/useModalWithSteps";
// request
import filesRequests from "../../../request/files";
import categoriesRequests from "../../../request/categories";

import s from './styles/createCategoryForm.module.css';


function CreateCategoryForm() {
	const navigate = useNavigate();

	const { 
		modalShow,
		setModalShow,
		modalCurrentStep,
		setModalCurrentStep 
	} = useModalWithSteps();

	const [errors, setErrors] = useState({ errorExists: false, errorList: [] });
	const [inputValues, setInputValues] = useState({ name: '', color: '#ffffff', backgroundColor: '#ffffff', parentCategoryId: null });
	const [imageFile, setImageFile] = useState(null);
	const [songsObj, setSongsObj] = useState({ files: [], names: [], ids: [] });

	const handleChange = (ev) => {
		const { name, value } = ev.target;
		setInputValues({
			...inputValues,
			[name]: value
		});
	}

	// verify if a name is empty and replace it with the original file name
	const _verifySongsNames = (songsFiles, songsNames) => {
		if(songsFiles.length === 0) return;

		let namesArr = [];
		for (let i = 0; i < songsFiles.length; i++) {
			namesArr[i] = songsNames[i] || getNameWithoutExt(songsFiles[i].name);
		}
		return namesArr;
	}


	const _verifyBeforeSubmit = () => {
		let _errors = [];

		if(!inputValues.name) 			 _errors.push('Category name needed');
		if(!inputValues.color) 			 _errors.push('Color badge needed');
		if(!inputValues.backgroundColor) _errors.push('Bacground color needed');
		// if(!imageFile) 					_errors.push('Category image needed');
		// if(songsObj.files.length === 0) _errors.push('Category needs at least one song');
	
		return {
			errorExists: _errors.length > 0,
			errorList: _errors
		}
	}

	const handleSubmit = async (ev) => {
		ev.preventDefault();
		const { files: songFiles, names: songNames } = songsObj;
		const { errorExists, errorList } = _verifyBeforeSubmit();
		let imageSaved, songsSaved;

		setErrors({ errorExists, errorList });
		
		if(errorExists) return;

		const finalSongNames = _verifySongsNames(songFiles, songNames);
		
		try {
			setModalShow(true);
			// 1) first, save image if exists
			setModalCurrentStep('Creating category');
			if(imageFile){
				imageSaved = await filesRequests.uploadImage(imageFile);
			}
	
			// 2) second, save songs if exists
			if(songFiles.length){
				songsSaved = await filesRequests.uploadSongs(songFiles, finalSongNames);
			}
			
			// 3) third, save category
			const createdCategory = await categoriesRequests.createCategory({
				parentCategoryId: inputValues.parentCategoryId || null,
				name: inputValues.name,
				color: inputValues.color,
				backgroundColor: inputValues.backgroundColor,
				...( imageSaved && {imageId: imageSaved._id}),
				...( songsSaved && {musicIds: songsSaved.map( s => s._id )})
			});
			console.log(createdCategory);
	
			// 4) final, redirect
			setModalCurrentStep('Category succesfully created, redirecting...');
			await wait(2000);
			
			navigate('/pages/categories');

		} catch (_err) {
			setModalShow(false);
			setModalCurrentStep("");
			setErrors({ errorExists: true, errorList: [
				_err.message
			]});
		}
	}


	return (
		<div className={s.container}>
			<ModalStepsLoader show={modalShow} currentStepText={modalCurrentStep} />

			<SimplePageTitle>
				Create new category
			</SimplePageTitle>
			
			<Card>
				<Card.Body>
					<Form onSubmit={handleSubmit} id="categoryForm">

						{
							errors.errorList.map( (errMsg, idx) => (
								<Alert className="my-3" variant="danger" key={idx}>
									<div className="alert-message">{errMsg}</div>
								</Alert>
								)
							)
						}

						<Row>
							<Col>
								<Form.Group className="mb-3">
									<Form.Label>Category name</Form.Label>
									<Form.Control 
										type="text" 
										name="name" 
										placeholder="name" 
										onChange={handleChange} 
										autoComplete="off"
										required
									/>
								</Form.Group>
							</Col>
							<Col>
								<div className="d-flex">
									<Form.Group className="mb-3 mx-5">
										<Form.Label>Category color</Form.Label>
										<Form.Control 
											type="color" 
											name="color" 
											defaultValue="#ffffff" 
											onChange={handleChange} 
											style={{width: '80px'}}
										/>
									</Form.Group>
									<Form.Group className="mb-3">
										<Form.Label>Category background color</Form.Label>
										<Form.Control 
											type="color" 
											name="backgroundColor" 
											defaultValue="#ffffff" 
											onChange={handleChange} 
											style={{width: '80px'}}
										/>
									</Form.Group>
								</div>
							</Col>
						</Row>
						
						<ParentCategorySelect handleChange={handleChange} />

						<ImageForm setImageFile={setImageFile} pageName="Category" />
						
						<AudiosForm setSongsObj={setSongsObj} songsObj={songsObj} />

						<Button type="submit" size="lg" variant="primary">Create category</Button>
					</Form>
				</Card.Body>
			</Card>
		</div>
	);
}

export default CreateCategoryForm;
