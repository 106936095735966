import filesRequests from "../request/files";
import FontFaceObserver from "fontfaceobserver";
import { hasHebrewCharacters } from "./text";
import fonts from "./fonts.json";

function createFontsCssCode(fontsArr) {
    let cssCode = "";
    for (let font of fontsArr) {
        if (!font) {
            continue;
        }
        cssCode += `
            @font-face {
                font-family: ${font.name};
                src: url('${font.path}');
                font-weight: ${font.weight};
            }
        `;
    }

    return cssCode;
}

function injectFontsInHeader(fontsCssCode) {
    const style = document.createElement("style");
    style.textContent = fontsCssCode;
    document.head.appendChild(style);
}

function injectTextForTesting(fontsArr) {
    const testFontsWrapper = document.createElement("div");
    testFontsWrapper.style.position = "fixed";
    testFontsWrapper.style.top = "-20000px";
    testFontsWrapper.style.opacity = "0";

    for (const font of fontsArr) {
        if (!font) {
            continue;
        }
        const { name, weight } = font;
        const div = document.createElement("div");
        div.textContent = name + " " + weight;
        div.style.fontFamily = name;
        div.style.fontSize = "25px";
        div.style.fontWeight = weight;
        div.style.color = "#000";

        testFontsWrapper.append(div);
    }
    document.body.append(testFontsWrapper);
}

const loadFontByNameAndWeight = async (name, weight) => {
    // let font = fonts.find((item) => item.name === name && item.weight === weight);

    const font = await filesRequests.getFontByNameAndWeight(name, weight);
    const fontsCssCode = createFontsCssCode([font]);
    injectFontsInHeader(fontsCssCode);
    injectTextForTesting([font]);
};

const loadedFonts = [];

// load custom font twice if doesn't load first time
export async function loadCustomFont({ fontFamily, fontWeight, text, callback, timeout = 3000 }) {
    if (loadedFonts.some((item) => item.fontFamily === fontFamily && item.fontWeight === fontWeight)) {
        return;
    }
    await loadFontByNameAndWeight(fontFamily, fontWeight);

    loadedFonts.push({ fontFamily, fontWeight });
    try {
        const fontObserver = new FontFaceObserver(fontFamily, {
            weight: fontWeight,
        });
        const testString = hasHebrewCharacters(text) ? text : null;

        await fontObserver.load(testString, timeout);
        // console.log(`${fontFamily} ${fontWeight} success loaded`);
        callback();
        return;
    } catch (err) {
        let errorMsg = `Can't load the ${fontFamily} font, weight: ${fontWeight}, ${err.message}`;
        console.error(errorMsg);
    }
}
