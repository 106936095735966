import React, { useState, useRef } from "react";
import { Form, Row, Col, Spinner } from "react-bootstrap";
import { Button } from 'antd';
import Swal from "sweetalert2";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faGripLines } from '@fortawesome/free-solid-svg-icons';
import { toast } from 'react-toastify';
// drag and drop
import SortableList, { SortableItem } from 'react-easy-sort'
import { arrayMoveImmutable } from 'array-move'
// utils
import { getNameWithoutExt } from '../../../utils/extensions';
// requests
import categoriesRequests from "../../../request/categories";
import filesRequests from "../../../request/files";
// image
import musicIcon from '../../../assets/img/icons/music-icon.svg';
// style
import s from './styles/createCategoryForm.module.css';

let songNameAjaxTimeout; // timeout for change the song name while typing (just if is editing)


function AudiosForm({ 
    songsObj, 
    setSongsObj, 
    setDisableSubmitBtn=()=>{}, 
    isEditing=false, 
    categoryId=null
}) {
    const [urlsPreviews, setUrlsPreviews] = useState(isEditing ? songsObj.files : []);
    const [loadingDelete, setLoadingDelete] = useState(false);
    const [changingNameAjax, setChangingNameAjax] = useState(false);
    const moreSongsInputRef = useRef();

    const clearValues = () => {
        // free space
        urlsPreviews.forEach( url => URL.revokeObjectURL(url));
        setUrlsPreviews([]);
        setSongsObj({ files: [], names: [] });
    }

    const clearPreview = (idx) => {
        const urlsCopy = [...urlsPreviews];
        // free space
        URL.revokeObjectURL(urlsPreviews[idx]);
        urlsCopy.splice(idx, 1);
        setUrlsPreviews([...urlsCopy]);
    }

    const handleChange = async ev => {
        const files = [...ev.target.files];

        if(files.length){
            // assing default filenames
            const names = files.map(f => getNameWithoutExt(f.name));
            const urls = files.map(f => URL.createObjectURL(f));

            setUrlsPreviews(urls);
            setSongsObj({ files, names, ids: [] });
            ev.target.value = "";
        }else{
            clearValues();
        }
    }

    const handleChangeSongName = (ev, idx) => {
        const { value } = ev.target;
        const namesCopy = [...songsObj.names];
        namesCopy[idx] = value;
        setSongsObj({ ...songsObj, names: namesCopy });
        if(isEditing){
            handleChangeSongNameAJAX(value, idx);
        }
    }


    const handleChangeSongNameAJAX = (value, idx) => {
        const fileId = songsObj.ids[idx];
        clearTimeout(songNameAjaxTimeout);

        songNameAjaxTimeout = setTimeout(async () => {
            setChangingNameAjax(true);
            await filesRequests.editFile(fileId, { filename: value });
            toast('Name changed', { type: 'success', position: 'bottom-center' })
            setChangingNameAjax(false);
        }, 1000);
    }


    const handleDelete = async (idx) => {
        console.log(idx);
        setLoadingDelete(true);
        const namesCopy = [...songsObj.names];
        const filesCopy = [...songsObj.files];
        
        if(isEditing){
            const idsCopy = [...songsObj.ids];
            setDisableSubmitBtn(true);
            const deleted = await categoriesRequests.deleteCategorySong(categoryId, idsCopy[idx]);
            console.log(deleted);
            
            namesCopy.splice(idx, 1);
            filesCopy.splice(idx, 1);
            idsCopy.splice(idx, 1);
            setSongsObj({ names: namesCopy, files: filesCopy, ids: idsCopy });
            clearPreview(idx);

            setDisableSubmitBtn(false);
        }
        else{
            namesCopy.splice(idx, 1);
            filesCopy.splice(idx, 1);
            setSongsObj({ names: namesCopy, files: filesCopy });
            clearPreview(idx);
        }

        setLoadingDelete(false);
    }


    const handleAddMoreSongs = () => {
        let input = moreSongsInputRef.current;

        input.onchange = ev => {
            const newFiles = [...ev.target.files];
            if(!newFiles.length) return;

            // assing default filenames
            const newNames = newFiles.map( f => getNameWithoutExt(f.name)); 
            const newUrls = newFiles.map(f => URL.createObjectURL(f));
    
            setUrlsPreviews( prevUrls => [...prevUrls, ...newUrls]);
            setSongsObj( prevState => ({
                names: [...prevState.names, ...newNames],
                files: [...prevState.files, ...newFiles],
                ids: [] // void array because backend will assing id later (mongodb id)
            }));
            input.value = "";
        }

        input.click();
    }


    const handleAddMoreSongsEdit = () => {
        let input = moreSongsInputRef.current;

        input.onchange = async ev => {
            const newFiles = [...ev.target.files];
            if(!newFiles.length) return;
            // assing default filenames
            const newNames = newFiles.map( f => getNameWithoutExt(f.name)); 
            const newUrls = newFiles.map(f => URL.createObjectURL(f));
            Swal.showLoading();

            const addedSongs = await filesRequests.uploadSongs(newFiles, newNames);
            const newSongsIds = addedSongs.map( s => s._id);

            const newMusicIds = [...newSongsIds, ...songsObj.ids];

            const categoryUpdated = await categoriesRequests.updateCategory({
                _id: categoryId,
                musicIds: newMusicIds
            });
            console.log(categoryUpdated);
            // hide loader
            document.querySelector('.swal2-container')?.click();
    
            setUrlsPreviews( prevUrls => [...newUrls, ...prevUrls]);
            setSongsObj( prevState => ({
                names: [...newNames, ...prevState.names],
                files: [...newFiles, ...prevState.files],
                ids: newMusicIds
            }));
            input.value = "";
        }

        input.click();
    }

    const onSortEnd = async (oldIndex, newIndex) => {
        let infoToastId;
        if(isEditing){
           infoToastId = toast('saving songs new order', { type: 'info', position: 'bottom-center' });
        }

        try {
            const namesArr = arrayMoveImmutable(songsObj.names, oldIndex, newIndex);
            const filesArr = arrayMoveImmutable(songsObj.files, oldIndex, newIndex);
            const idsArr = arrayMoveImmutable(songsObj.ids, oldIndex, newIndex);
            const urlsPreviewsArr = arrayMoveImmutable(urlsPreviews, oldIndex, newIndex);

            setUrlsPreviews(urlsPreviewsArr);
            setSongsObj({names: namesArr, files: filesArr, ids: idsArr});

            if(!isEditing) return;

            const edited = await filesRequests.updateSongsOrderIndex(idsArr);
            console.log(edited);
            toast.dismiss(infoToastId);
            toast('Songs order saved', { type: 'success', position: 'bottom-center', autoClose: 2000 });
            
        } catch (err) {
            console.error(err);
            toast.dismiss(infoToastId);
            toast("Error saving songs order", { type: 'error', position: 'bottom-center' });
        }
    }

    console.log(songsObj);

    return (
        <div className={s.audiosContainer}>
            <div className="d-flex">
                Music 
                <img src={musicIcon} alt="icon music" style={{ width: '22px' }} />
            </div>
            {
                !isEditing && (
                    <Form.Group className="mb-3">
                        <Form.Label>Category music</Form.Label>
                        <Form.Control 
                            type="file" 
                            name="songs"
                            accept="audio/*" 
                            onChange={handleChange}
                            multiple 
                        />
                    </Form.Group>
                )
            }

            {
                urlsPreviews.length !== 0 && (
                    <SortableList  onSortEnd={onSortEnd} className="list" draggedItemClassName="dragged">
                        {
                            urlsPreviews.map((url, idx) => (
                                <SortableItem key={url}>
                                    <Row className={s.songsRow}>
                                        <Col className={[s.moveIcon, 'col-md-auto']}>
                                            <FontAwesomeIcon icon={faGripLines} size="lg" />
                                        </Col>
                                        <Col>
                                            <Form.Group className="my-1">
                                                <div className="d-flex align-items-center">
                                                    { changingNameAjax && <Spinner size="sm" animation="border"/> }
                                                    <Form.Control 
                                                        type="text" 
                                                        name="songs"
                                                        accept=".mp3"
                                                        placeholder="leave blank for default name" 
                                                        onChange={ ev => handleChangeSongName(ev, idx) }
                                                        value={songsObj.names[idx]}
                                                        multiple 
                                                    />
                                                </div>
                                            </Form.Group>
                                        </Col>
                                        <Col className={[s.audioPreview, 'col-md-auto']}>
                                            <audio controls src={url} />
                                        </Col>
                                        <Col className="col-md-auto">
                                            <Button 
                                                onClick={() => handleDelete(idx)} 
                                                disabled={loadingDelete} 
                                                loading={loadingDelete} 
                                                type="primary" 
                                                danger
                                                size="small">
                                                    {loadingDelete ? 'Wait' : 'Delete'}
                                            </Button>
                                        </Col>
                                    </Row>
                                </SortableItem>
                            ))
                        }
                    </SortableList>
                )
            }

            {
                ((urlsPreviews.length > 0) || isEditing) && (
                    <div>
                        <Form.Control 
                            type="file" 
                            name="songs-extras"
                            accept="audio/*"
                            multiple
                            style={{ display: 'none' }} 
                            ref={moreSongsInputRef}
                        />
                        <Button 
                            onClick={isEditing ? handleAddMoreSongsEdit : handleAddMoreSongs} 
                            size="middle" 
                            type="primary" 
                            className="my-3">
                                Add more songs +
                        </Button>
                    </div>
                )
            }


        </div>
    )
}

export default AudiosForm;