import React, { useState, useEffect } from "react";
import { useParams, Link } from 'react-router-dom';
import { Card, Row, Col, Spinner } from "react-bootstrap";
// custom components
import { SimplePageTitle } from "../../../components/titles/pageTitles";
import CategoryButtonGroup from "./ButtonGroup";
import SortableCategoryContainer from "./sortableCategoryContainer";
// custom hooks
import { useIsMounted } from "../../../hooks/useIsMounted";
// request
import categoriesRequests from "../../../request/categories";

import s from './styles/detail.module.css';


function CategoryDetail() {
    const { categoryId } = useParams();
    const isMounted = useIsMounted();

    const [loadingData, setLoadingData] = useState(true);
    const [categoryData, setCategoryData] = useState({});


    useEffect(() => {
        categoriesRequests.getCategory(categoryId)
			.then( _categoryData => {
				if(isMounted.current){
					setCategoryData(_categoryData)
				}
			})
			.finally(() => {
				if(isMounted.current) setLoadingData(false);
			})
    }, [categoryId, isMounted]);


    if(loadingData) return <Spinner  animation="border" className="d-flex justify-content-center" />

	return (
		<div className={s.container}>
			<SimplePageTitle>
				Details of <i>{ categoryData.name }</i> category
			</SimplePageTitle>
			
			<Card className="mb-40">
				<Card.Body>
                    <Row>
                        {/* info */}
                        <Col>
                            <h4 className="h4">Name</h4>
                            <p>{categoryData.name}</p>
                        </Col>
                        <Col>
                            <h4 className="h4">Color</h4>
                            <p>{categoryData.color}</p>
                            <div className={s.colorData} style={{backgroundColor: categoryData.color }} />
                        </Col>
                        <Col>
                            <h4 className="h4">Background Color</h4>
                            <p>{categoryData.backgroundColor}</p>
                            <div className={s.colorData} style={{backgroundColor: categoryData.backgroundColor }} />
                        </Col>
                        <Col>
                            <h4 className="h4">Parent category</h4>
                            {
                                categoryData.parentCategoryId ? (
                                    <Link to={`/pages/category/${categoryData.parentCategoryId}`}>
                                        {categoryData.parentCategoryName}
                                    </Link>
                                ) : (
                                    <p>N/A</p>
                                )
                            }
                        </Col>
                    </Row>
                    <Row>
                        <h4 className="h4">Actions:</h4>
                        <CategoryButtonGroup
                            categoryId={categoryId}
                            categoryName={categoryData.name}
                            noEyeBtn
                        />
                    </Row>
				</Card.Body>
			</Card>


            <Card className="mb-40">
                <Card.Body>
                    <h4 className="h4">Category music</h4>
                    {
                        categoryData.musicIds?.length ? (
                            <>
                            {
                                categoryData.musicIds.map( (musicObj, idx) => (
                                    <div key={idx} className={s.audioContainer}>
                                        <div>{musicObj.filename}</div>
                                        <audio src={musicObj.path} controls />
                                    </div>
                                ))
                            }
                                <h4 className="h4">Go to edit music</h4>
                                <CategoryButtonGroup
                                    categoryId={categoryId}
                                    categoryName={categoryData.name}
                                    noEyeBtn
                                    noDeleteBtn
                                />
                            </>
                        ) : (
                            <div>This category doesn't have any music</div>
                        )
                    }
                </Card.Body>
            </Card>

            <Card className="mb-40">
                <Card.Body>
                    <h4 className="h4">Category image</h4>
                    {
                        categoryData.imageId ? (
                            <img
                                width={400} 
                                src={categoryData.imageId.path} 
                                alt="Category images" 
                            />
                        ) : (
                            <p>This category has no image</p>
                        )
                    }
                </Card.Body>
            </Card>

            <Card>
                <Card.Body>
                    <h4 className="h4">Sub categories</h4>
                    {
                        categoryData.subCategories.length !== 0 ? (
                            <SortableCategoryContainer
                                categoriesArray={categoryData.subCategories}
                            />
                        ) : (
                            <p>This category doesn't have sub-categories.</p>
                        )
                    }
                </Card.Body>
            </Card>
		</div>
	);
}

export default CategoryDetail;
