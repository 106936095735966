import ajax from './ajax';
import { API_BASE_URL, WP_API_BASE_URL } from '../config';
// utils
import { JSON2FormData } from '../utils/formData';

const customerPurchasesRequests = {};

// --- MongoDB ---

customerPurchasesRequests.getCustomerPurchasesByEmail = async function ({ customerEmail }) {
    try {
        const url = `${API_BASE_URL}/purchases/GetCustomerPurchases?customerEmail=${customerEmail}`;
        const purchases = await ajax.get(url, { token: true });

        return purchases;
    } catch (err) {
        console.log('Error getting customer orders by email');
        return null;
    }
}


customerPurchasesRequests.initEditCount = async function ({
    wooCartItemKey,
    orderId,
    wooOrderItemId,
    customerEmail
}) {
    const url = `${API_BASE_URL}/purchases/InitEditCount`;

    try {
        const countStarted = await ajax.post(url, {
            wooCartItemKey,
            orderId,
            wooOrderItemId,
            customerEmail
        });
        return countStarted;
        
    } catch (error) {
        console.log('Error initializing edit count', error);
        return null;
    }
}

// --- Wordpress ---

customerPurchasesRequests.getWooOrderItem = async function ({ wooCartItemKey, wooOrderId, wooOrderItemId }) {
    const formData = JSON2FormData({
        woo_order_id: wooOrderId,
        woo_order_item_id: wooOrderItemId,
        woo_cart_item_key: wooCartItemKey
    });

    try {
        const response = await fetch(`${WP_API_BASE_URL}/get_order_item.php`, {
            method: 'POST',
            body: formData
        });
        const json = await response.json();
        if (json.data.message) return null; // wordpress api error message
        return json.data;

    } catch (err) {
        console.log('Error getting woo order item');
        return null;
    }
}


customerPurchasesRequests.updateWooOrderItem = async function({
    woo_order_id,
    woo_order_item_id,
    woo_cart_item_key,
    customization_data,
    customized_item_preview
}) {
    const formData = JSON2FormData({ 
        woo_order_id,
        woo_order_item_id,
        woo_cart_item_key,
        customization_data,
        customized_item_preview
    });

    try {
        const response = await fetch(`${WP_API_BASE_URL}/update_order_item.php`, {
            method: 'POST',
            body: formData,
            // credentials: 'include'
        });

        const json = await response.json();
        return json.data;

    } catch (err) {
        console.log('Error updating order item in wordpress');
        console.log(err);
        return false;
    } 
}

export default customerPurchasesRequests;