import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Card, Spinner } from 'react-bootstrap';
import { Button } from 'antd';
// import { DownloadOutlined, EditFilled } from '@ant-design/icons';

// requests
import customerPurchasesRequests from '../../../../request/customerPurchases';
import './style/purchasesListItem.css';


const PurchasedItem = ({
    wooOrderItemId, 
    wooCartItemKey, 
    orderId, 
    creationDateMS, 
    customerEmail,
    fullCustomizationData = null,
    customizedItemPreview = "",
    resultFullData = null
}) => {
    const [loading, setLoading] = useState(true);
    const [data, setData] = useState({});
    const navigate = useNavigate();

    useEffect(() => {
        // data gotten from full edit link
        if(fullCustomizationData){
            setData({
                productName: fullCustomizationData.productName,
                preview: customizedItemPreview,
                hasQR: !!fullCustomizationData.QRText,
                hasSong: !!fullCustomizationData.song,
                fullData: resultFullData,
                customerEmail: null,
                creationDate: null,
            })
            setLoading(false);
        }
        // data gotten typing user email
        else{
            customerPurchasesRequests.getWooOrderItem({
                wooCartItemKey,
                wooOrderItemId,
                wooOrderId: orderId
            }).then( _data => {
                const { customized_item_preview, customization_data } = _data;
                const creationDate = creationDateMS ? new Date(creationDateMS).toLocaleDateString() : null;
    
                setData({
                    productName: customization_data.productName,
                    preview: customized_item_preview,
                    hasQR: !!customization_data.QRText,
                    hasSong: !!customization_data.song,
                    fullData: _data,
                    customerEmail: customerEmail || null,
                    creationDate,
                })
                setLoading(false);
            })
        }
    }, [fullCustomizationData]);

    const gotoEditPage = () => {
        navigate('/pages/edit-customer-card-editor', {
            state: data
        });
    }

    // const handleDownloadModal = () => {
    //     console.log('download modal');
    // }

    return (
        <Card>
            <Card.Body>
                {
                    loading ? <Spinner animation="border" variant="primary" /> : (
                        // pi => purchased item
                        <div className="pi">
                            <div className="pi-top-wrapper">
                                <div className="pi-left">
                                    <img src={data.preview} alt="thumbnail" />
                                </div>
                                <div className="pi-right">
                                    <div className="pi-detail title">{data.productName}</div>
                                    <div className="pi-detail">QR: {data.hasQR ? 'Yes' : 'No'}</div>
                                    <div className="pi-detail">Song: {data.hasSong ? 'Yes' : 'No'}</div>
                                    { customerEmail && <div className="pi-detail">Associated email: {customerEmail}</div> }
                                    { data.creationDate && <div className="pi-detail">Created at: {data.creationDate}</div> }
                                </div>
                            </div>
                            <div className="pi-bottom-wrapper">
                                <Button onClick={gotoEditPage} type="primary">Edit and download</Button>
                                {/* <Button onClick={handleDownloadModal} type="primary" color="#AF40F7" icon={<DownloadOutlined/>} style={{ marginLeft: '10px' }}>Download</Button> */}
                            </div>
                        </div>
                    )
                }

            </Card.Body>
        </Card>
    );
}
 
export default PurchasedItem;