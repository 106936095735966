import ajax from './ajax';
import { API_BASE_URL } from '../config';

const miscellaneousRequest = {};

// watermarks
miscellaneousRequest.setDefaultWatermarkId = async function (fileId) {
    const url = `${API_BASE_URL}/miscellaneous/SetDefaultWatermarkId`;

    const updated = await ajax.post(url, { newId: fileId }, { token: true });
    return updated;
}


miscellaneousRequest.getDefaultWatermarkId = async function () {
    const url = `${API_BASE_URL}/miscellaneous/GetDefaultWatermarkId`;

    const defaultWatermarkId = await ajax.get(url, { token: true });
    return defaultWatermarkId;
}

/**
 * 
 * @returns {Promise<number>}
 */
miscellaneousRequest.getLatestOrderItemId = async function () {
    const url = `${API_BASE_URL}/miscellaneous/GetLatestOrderItemId`;

    const id = await ajax.get(url, { token: true });
    return id;
}



export default miscellaneousRequest;