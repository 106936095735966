import { nanoid } from 'nanoid';
import { fabric } from 'fabric';
import { showWarningToast } from '../../../utils/warning';


function duplicateCurrentObject({ canvas, addEventsCb, onAddedCallback, objToDuplicate = null }) {
    const _objToDuplicate = objToDuplicate || canvas.getActiveObject();

    if (!_objToDuplicate) {
        showWarningToast();
        return;
    };

    const { label, autocompleteLabel, productId, lockScalingY, borderColor, indexOrder, originalText, afterText, beforeText } = _objToDuplicate;
    let _obj = JSON.parse(JSON.stringify(_objToDuplicate));

    const activeObjCopy = {
        ..._obj,
        label,
        autocompleteLabel,
        lockScalingY,
        borderColor,
        productId,
        _id: nanoid(),
        tempId: nanoid(10),
        indexOrder,
        originalText,
        afterText,
        beforeText
    }

    // duplicate object
    const newTxtObject = new fabric.Textbox(_objToDuplicate.text, {
        ...activeObjCopy,
        editable: true
    });


    // modify position a little bit and add to canvas
    newTxtObject.top += 25;
    // newTxtObject.left += 20;
    newTxtObject.tempId = nanoid(10);
    newTxtObject._id = nanoid(10);
    newTxtObject.indexOrder = indexOrder ? (indexOrder + 1) : 0;

    canvas.add(newTxtObject);

    newTxtObject.__eventListeners = {};
    addEventsCb(newTxtObject);

    canvas.setActiveObject(newTxtObject);
    canvas.renderAll();

    // force active object
    if (objToDuplicate) {
        setTimeout(() => {
            canvas.setActiveObject(newTxtObject);
            canvas.renderAll();
        }, 10);
    }

    addEventsCb(newTxtObject);
    onAddedCallback(canvas.getObjects());
}


function duplicateCurrentObjectController({ canvas, addEventsCb, onAddedCallback }) {

    function keyboardListener(event) {
        //ctrl + x  
        if (event.ctrlKey && event.code === 'KeyX') {
            duplicateCurrentObject({ canvas, addEventsCb, onAddedCallback });
        }
    }

    // remove previous listener
    window.removeEventListener('keyup', keyboardListener);

    //Listen for undo/redo 
    window.addEventListener('keyup', keyboardListener);
}

export {
    duplicateCurrentObjectController,
    duplicateCurrentObject
}